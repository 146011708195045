#imgGrid{
    display: grid;
    display:grid;
    grid-template-columns: repeat(3,1fr);
    width:100%;
    margin-left:auto;
    margin-right:auto;
    text-align: center;
}
.contactBackground{
    object-fit: cover;
    width:100%;
    height:75vh;
    opacity: 0.1;
}
.iconContact{
    opacity: 1;
    height:4vw;
    width:4vw;
    position: absolute;
    top:45vh;
    margin-left:-18.66vw;
    filter:invert(0.8);
}
.contactText1{
    font-size: 2vw;
    position: absolute;
    top:35vh;
    margin-left:16.66vw;
    transform: translateX(-50%);
    margin-right:0;
}

.contactText2{
    font-size: 1.5vw;

    position: absolute;
    top: 55vh;
    margin-left:-16.66vw;
    transform: translateX(-50%);
    margin-right:0;
}

#footerAccueil{
    position: absolute;
    bottom:0;
    width:100vw;
}


@media all and (orientation: portrait){
    #imgGrid{
        display:block;
        margin:0;
    }
    #imgGrid div{
        display:block;
        margin:0;
        height:29vh;
    }
    .contactBackground{
        object-fit: cover;
        width:100%;
        height:29vh;
        opacity: 0.1;
        margin:0;
    }
    .iconContact{
        height:8vw;
        width:8vw;
        position:relative;
        display:block;
        top:-30vh;
        left:50vw;
        transform: translateX(-50%);
        margin-left:0vw;
    }
    .contactText1{
        font-size:5vw;
        position:relative;
        display:block;
        top:-28vh;
        
        transform: translateX(-50%);
        left:50vw;
        margin-left:0vw;
    }
    
    .contactText2{
        font-size:5vw;
        position:relative;
        display:block;
        
        transform: translateX(-50%);
        top:-28vh;
        left:50vw;
        margin-left:0vw;
    }
    
    #footerAccueil{
        position: relative;
        bottom:0;
        width:100vw;
    }



}


#accueilMain{
    margin:0;
    min-height: 100vh;
}
#footerAccueil{
    position: absolute;
    bottom:0;
    width:100vw;
}
#accueilCards{
    margin-top:4vw;
    display:grid;
    grid-template-columns: repeat(4,1fr);
    
    margin-left:auto;
    margin-right:auto;
}
#accueilCards div{
    border-radius: 12px;
    width:17vw;
    background-color: grey;
    margin-left:auto;
    margin-right:auto;
}
#accueilCards div img{
    border-radius: 12px;;
    width:17vw;
    height:19vw;
    object-fit: cover;
    background-color: grey;
    margin-left:auto;
    margin-right:auto;
    z-index: 0;
}

#accueilCards div div{
    background-color: #212041;
    border-radius: 47px 0px 12px 12px;
    position:relative;
    width:17vw;
    height:7vw;
    object-fit: cover;
    margin-top:-5vw;
    margin-left:auto;
    margin-right:auto;
    z-index: 1;
    text-align:center;
    transition: 0.2s;
}

#accueilCards div:hover div{
    
    border-radius: 0px 0px 12px 12px;
    margin-top:-6vw;
    height:8vw;
}
#accueilCards div div div{
    position:static;
}
div .cardTitle{
    padding-top:1.5vw;
    font-size: 2vw;
    margin:0vw;
    margin-left:auto;
    margin-right:auto;
}
div .prix1{
    
    font-size: 1.2vw;
    position: absolute;
    bottom:0.1vw;
    left:0.5vw;
    margin:0vw;
}
div .prix2{

    font-size: 1.2vw;
    position: absolute;
    bottom:0.1vw;
    right:0.5vw;
    margin:0vw;
}
div .prix3{

    font-size: 1.2vw;
    position: absolute;
    bottom:1.5vw;
    left:0.5vw;
    margin:0vw;
}

@media all and (orientation: portrait){
    #accueilMain{
        height:100vh;
    }
    #accueilCards{
        margin-top:4vw;
        display:grid;
        grid-template-columns: repeat(2,1fr);
        
        margin-left:auto;
        margin-right:auto;
        
    }
    #accueilCards div{
        margin-top:5vw;
        width:40vw;
    }
    #accueilCards div img{
        border-radius: 12px;;
        width:40vw;
        height:40vw;
    }
    #accueilCards div div{
        background-color: #212041;
        border-radius: 20px 0px 12px 12px;
        width:40vw;
        height:15vw;
        margin-top:-15vw;
    }
    #accueilCards div:hover div{
        background-color: #212041;
        border-radius: 0px 0px 12px 12px;
        width:40vw;
        height:15vw;
        margin-top:-15vw;
    }
    #accueilCards div .cardTitle{
        padding-top:4vw;
        font-size: 5vw;
    }
    #accueilCards div .prix1{
        
        font-size:2.5vw;
        position: absolute;
        bottom:0.1vw;
        left:2vw;
        margin:0vw;
    }
    #accueilCards div .prix2{
    
        font-size:2.5vw;
        position: absolute;
        bottom:0.1vw;
        right:2vw;
        margin:0vw;
    }
    
    #footerAccueil{
        position:absolute;
        width:100vw;
    }
    
}
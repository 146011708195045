#californienBody{
    display: flex;
    flex-wrap: wrap;
}
p{
    display:block;
    font-size: 1.5vw;
}
#divFlex{
    
    flex-basis: 60%;
}
#californienText1{
    margin-top:3vw;
    margin-left:2vw;
}
#californienText3{
    margin-top:3vw;
    margin-left:2vw;
}
#californienText5{
    margin-top:3vw;
    margin-left:2vw;
    flex-basis: 100%;
}

#californienText2{
    margin-top:2vw;
    margin-left:10vw;
    flex-basis: 30%;
}
#californienText4{
    margin-top:2vw;
    margin-left:10vw;
    flex-basis: 30%;
}
#californienText6{
    margin-top:0vw;
    margin-left:10vw;
    flex-basis: 100%;
}
#cardElement{
    display:block;
    margin-left:5vw;
    margin-top:5vw;
    border-radius: 12px;
    width:17vw;
    background-color: none;
}
#cardElement img{
    border-radius: 12px;;
    width:17vw;
    height:19vw;
    object-fit: cover;
    background-color: grey;
    margin-left:auto;
    margin-right:auto;
    z-index: 0;
}

#cardElement div{
    background-color: #212041;
    border-radius: 47px 0px 12px 12px;
    position:relative;
    width:17vw;
    height:7vw;
    object-fit: cover;
    margin-top:-5vw;
    margin-left:auto;
    margin-right:auto;
    z-index: 1;
    text-align:center;
    transition: 0.2s;
}

#cardElement:hover div{
    
    border-radius: 0px 0px 12px 12px;
    margin-top:-6vw;
    height:8vw;
}
#cardElement div div{
    position:static;
}
div .cardTitle{
    padding-top:1.5vw;
    font-size: 2vw;
    margin:0vw;
    margin-left:auto;
    margin-right:auto;
}
div .prix1{
    
    font-size: 1.2vw;
    position: absolute;
    bottom:0.1vw;
    left:0.5vw;
    margin:0vw;
}
div .prix2{

    font-size: 1.2vw;
    position: absolute;
    bottom:0.1vw;
    right:0.5vw;
    margin:0vw;
}
#footerCalifornien{
    display:block;
    height:4.4vw;
}
@media all and (orientation: portrait){
    #californienText1{
        font-size:4vw;
    }
    #californienText3{
        font-size:4vw;
    }
    #californienText5{
        font-size:4vw;
    }
    
    #californienText2{
        font-size:4vw;
    }
    #californienText4{
        font-size:4vw;
    }
    #californienText6{
        font-size:4vw;
    }
    #californienBody #cardElement{
        display:block;
        margin-left:5vw;
        margin-top:5vw;
        border-radius: 12px;
        width:30vw;
        background-color: none;
    }
    #californienBody #cardElement img{
        border-radius: 12px;;
        width:30vw;
        height:40vw;
        object-fit: cover;
        background-color: grey;
        margin-left:auto;
        margin-right:auto;
        z-index: 0;
    }
    
    #californienBody #cardElement div{
        background-color: #212041;
        border-radius: 12px 0px 12px 12px;
        position:relative;
        width:30vw;
        height:15vw;
        object-fit: cover;
        margin-top:-15vw;
        margin-left:auto;
        margin-right:auto;
        z-index: 1;
        text-align:center;
        transition: 0.2s;
    }
    
    #californienBody #cardElement:hover div{
        
        border-radius: 0px 0px 12px 12px;
        height:15vw;
        margin-top:-15vw;
    }
    #californienBody #cardElement div div{
        position:static;
    }
    #californienBody div .cardTitle{
        padding-top:3vw;
        font-size: 3.5vw;
    }
    #californienBody div .prix1{
        
        font-size: 2.5vw;
        bottom:0.5vw;
        left: 2vw;
    }
    #californienBody div .prix2{
    
        font-size: 2.5vw;
        bottom:0.5vw;
        left: 2vw;
    }
}